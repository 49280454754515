$(document).ready(function () {
    let getParticipants = function () {
        $.ajax({
            type: 'POST',
            url: `/participants`,
            dataType: 'json',
            success: function (result) {
                if (result.status === 'filled') {
                    let $participantsContainer = $('.participants__info'),
                        participants          = [];

                    $('.participants__item').each(function (index, element) {
                        participants.push($(element).text());
                    });

                    result.data.forEach(function (element, index) {
                        if (participants.indexOf(element) === -1) {
                            $('.participants__empty').fadeOut();
                            $participantsContainer.append(`<p class="participants__item">${element}</p>`);
                        }
                    });
                }
            },
            error: function (error) {
                console.log(error);
            }
        })
    }

    setInterval(getParticipants, 10000);
});